import { render, staticRenderFns } from "./FairLabel.vue?vue&type=template&id=35f69c50&scoped=true"
import script from "./FairLabel.vue?vue&type=script&lang=js"
export * from "./FairLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f69c50",
  null
  
)

component.options.__file = "FairLabel.vue"
export default component.exports